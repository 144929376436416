<template>
  <div>
    <primaryButton
      v-if="functionElement"
      class="mr-2"
      :attributesArray="functionElement['attributesArray']"
      type="button"
      :value="functionElement['functionName']"
      @click="downLoadRcFile"
      :label="functionElement['functionName']"
      color="save"
      :disabled="!isValid || loading"
      :loading="loading && isValid"
      :icon="'mdi-download-box-outline'"
    ></primaryButton>
  </div>
</template>
<script>
import primaryButton from "@/commonComponents/primaryButton.vue";
export default {
  props: ["isValid", "loading", "functionElement", "result"],
  components: {
    primaryButton,
  },
  methods: {
    downLoadRcFile() {
      let user = this.result.valueLine.email;
      let text = this.result.valueLine.recoveryCode.join("\r\n");
      let filename = "Acctopus-2FA-Recovery-Codes("+user+").txt";
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      return true;
    },
  },
};
</script>